import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  name: 'bar-chart',
  props: ['data', 'labels'],
  mounted () {
    this.render()
  },
  computed: {
    dat () {
      return this.data
    }
  },
  watch: {
    'dat': function () {
      this.render()
    }
  },
  data () {
    return {
      options: {
      	responsive: true,
        legend: {
          labels: {
            fontSize: 16
          }
        },
        tooltips: {
          bodyFontSize: 17
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    }
  },
  methods: {
    render () {
      this.renderChart({
        labels: this.labels,
        datasets: this.dat
      }, this.options)
    }
  }

}
