<template lang="pug">
v-menu(v-model="editing" auto)
  template(v-slot:activator="{ on }")
    td(:class="computedClass", @click="togleState")
      .display-2(:class="textClass") {{ result }}
  .primary.text-center(v-if="editable")
    v-btn.text-center(x-large text block @click="setval(10)")
      .display-3.text-center.redcolor {{ 10 }}
    v-btn.pa-3.text-center(x-large text block @click="setval(20)")
      .display-3.text-center.grencolor {{ 20 }}
    v-btn.pa-3.text-center(x-large text block @click="setval(30)")
      .display-3.text-center.bluecolor {{ 30 }}
</template>
<script>

export default {
  name: 'ResultField',

  props: [
    'roundInfo',
    'perfect',
    'team',
    'mode',
    'value',
    'index',
    'editable'
  ],
  data () {
    return {
      cover: true,
      editing: false,
      newval: 0,
      options: [10, 20, 30],
      computedClass: ''
    }
  },
  watch: {
    value: { handler: 'computeClass' },
    mode: { handler: 'computeClass' },
    perfect: { handler: 'computeClass' },
    roundInfo: { handler: 'computeClass' }
  },
  computed: {
    result () {
      return this.roundInfo[this.mode]
    },
    serverCover () {
      return this.roundInfo.cover
    },
    textClass () {
      if ([3, 6, 7].includes(this.index)) return 'font-weight-black'
      return ''
    }
    // resToGet () {
    //   if (this.mode === 'Utilidad' || this.mode === 'Utility') {
    //     return 'utilidades'
    //   }
    //   if (this.mode === 'Negociacion' || this.mode === 'Negotation') {
    //     return 'negociacion'
    //   }
    //   if (this.mode === 'Acumulado' || this.mode === 'Acumulated') {
    //     return 'acumulado'
    //   }
    //   return 'edition'
    // },
    // computedClasses (team, index) {
    //   // this.newval = this.value
    //   let clases = ''
    //   // let cover = this.covers[index][team]
    //   // let negResponse = this.group['negociacion'][index][team]
    //   if (this.mode === 'edition') {
    //     if (this.serverCover && !this.perfect) clases += 'cover '
    //   } else if (this.result === 10) clases += 'redcolor '
    //   else if (this.result === 20) clases += 'grencolor '
    //   else if (this.result === 30) clases += 'bluecolor '
    //   if ([3, 6, 7].includes(this.result)) clases += 'specialRound'
    //   return clases
    // }
  },
  methods: {
    computeClass () {
      // this.newval = this.value
      let clases = ''
      let editest = this.mode !== 'edition'
      let utilityMode = (this.mode === 'negociacion') || !editest
      if (this.serverCover && !this.perfect && editest) clases += 'cover '
      else if (this.result === 10 && utilityMode) clases += 'red--text '
      else if (this.result === 20 && utilityMode) clases += 'green--text '
      else if (this.result === 30 && utilityMode) clases += 'blue--text '

      if ([3, 6, 7].includes(this.index)) clases += 'font-weight-black	'
      this.computedClass = clases
    },
    toggleCover () {
      // this.cover = !this.cover
      // this.computeClass()
      this.$alert('reconecting', 'Actualizando')
      this.$firestore.models.teams
        .toggleCovers(this.team, this.index)
        .then(result => console.log('success'))
    },
    togleState () {
      if (this.mode === 'edition') this.editing = !this.editing
      else this.toggleCover()
    },
    setval (v) {
      const self = this
      this.$alert('reconecting', 'Actualizando')
      this.$firestore.models.teams
        .setValue(this.team.id, this.index, v)
        .then(result => {
          self.$emit('cambio')
          console.log(result)
        })
      // this.$emit('setet', v)
      // // this.newval=v
      // this.computeClass()
    }
  },
  mounted () {
    this.newval = this.value
    this.computeClass()
  }
}
</script>
