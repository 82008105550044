<template lang="pug">
.angosta
  v-simple-table(style="max-width:20;" )
      thead
          tr.white--text
              th.alba.text-center
                  .display-2  X
              th.batia.text-center
                  .display-2  XX
      RoundsTableView(
        :perfect="true"
        :editable="false"
        :group="rounds")
</template>
<script>
import { perfectRounds } from '@/assets/dinamicaUtils'
import RoundsTableView from '@/components/results/RoundsTableView.vue'

export default {
  name: 'PerfectRounds',
  components: {
    RoundsTableView
  },
  data () {
    return {
      rounds: perfectRounds
    }
  }
}
</script>
