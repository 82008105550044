<template lang="pug">
v-expansion-panel(v-model="show" class="mt-2")

  ModalEdit(
    v-if="group && alba"
    ref="EditAlba"
    :action="$firestore.models.teams.partialUpdate"
    :instanceID="alba.id"
    :editInstance="alba"
    key="albaModal"
    )
  ModalEdit(
    ref="EditBatia"
    key="batiaModal"
    v-if="group && batia"
    :action="$firestore.models.teams.partialUpdate"
    :instanceID="batia.id"
    :editInstance="batia"
    )
  .container(v-if="group")
    .row.justify-end
      v-btn(@click="access = !access" text)
        v-icon(x-small) fas fa-key
      v-btn(@click="hide" text)
        v-icon fas fa-times
    .row.d-flex.justify-content-center
      .col.text-center
        .display-2 {{ $words.get('group_sin')}}  {{group.name }}-{{group.name }}{{group.name }}
    .row.d-flex.justify-content-between
      .col-6.text-center
        .display-1.alba--text Alba

        .bordered.ma-2.pa-4
          .ma-3.pa-3(v-editable @edit="$refs.EditAlba.open('objetive','text', $words.get('team_objective'))")
            .headline.font-weight-bold {{ $words.get('team_objective') }}
            .headline {{alba.objetive || $words.get('todefine') }}
        br
        .bordered.ma-2.pa-4
          .ma-3(v-editable @edit="$refs.EditAlba.open('estrategia','text', $words.get('teamstrategy'))")
            .headline.font-weight-bold {{ $words.get('teamstrategy') }}
            .headline {{alba.estrategia || $words.get('todefine')  }}
        .ma-4(v-if="access")
          .headline.mt-5.font-weight-bold Acceso
          .container.pa-3.pointer()
            .row.px-10
              v-textarea.pointer(
                v-clipboard="()=>albaLink"
                v-clipboard:success="alertCoppied"
                rows="2" style="font-size:11px"
                outlined auto-grow readonly
                :value="albaLink")
              v-icon.mb-6(@click="abrirVentana(albaLink)") fas fa-external-link-alt

      .col-6.text-center
        .display-1.batia--text Batia
        .bordered.ma-2.pa-4
          .ma-3(v-editable @edit="$refs.EditBatia.open('objetive','text', $words.get('team_objective'))")
            .headline.font-weight-bold {{ $words.get('team_objective') }}
            .headline {{batia.objetive || $words.get('todefine')  }}
        br
        .bordered.ma-2.pa-4
          .ma-3(v-editable @edit="$refs.EditBatia.open('estrategia','text', $words.get('teamstrategy'))")
            .headline.font-weight-bold {{ $words.get('teamstrategy') }}
            .headline {{batia.estrategia || $words.get('todefine')  }}

        .ma-4(v-if="access")
          .headline.mt-5.font-weight-bold Acceso
          .container.pa-3.pointer()
            .row.px-10
              v-textarea.pointer(
                 v-clipboard="()=>batiaLink"
                 v-clipboard:success="alertCoppied"
                rows="2" style="font-size:11px"
                outlined auto-grow readonly
                :value="batiaLink")
              v-icon.mb-6(@click="abrirVentana(batiaLink)") fas fa-external-link-alt
</template>
<script>
import { REF, perfect, groupName } from '@/assets/dinamicaUtils'
// import userAccess from './UserAccess'
export default {
  name: 'GroupDetail',
  // props:{
  //   show: {type: Boolean, default:false},
  //   group:{default: null},
  // },
  // components: { userAccess },
  data () {
    return {
      access: false,
      group: null,
      alba: null,
      batia: null,
      show: false,
      albaReference: null,
      batiaReference: null
    }
  },
  computed: {
    albaLink () {
      return `${window.location.protocol}//${window.location.host}/dashboard/team/${this.alba.id}`
    },
    batiaLink () {
      return `${window.location.protocol}//${window.location.host}/dashboard/team/${this.batia.id}`
    }

  },
  methods: {
    abrirVentana (link) {
      window.open(link)
    },
    link (id) {
      return `${window.location.protocol}//${window.location.host}/dashboard/team/${id}`
    },
    alertCoppied () {
      this.$alert('success', 'Link Copiado', this.$words.get('copied'))
    },
    showUniverse (group) {
      this.group = group
      this.alba = { ...group.alba }
      this.batia = { ...group.batia }
      this.albaReference = group.alba.reference
      this.batiaReference = group.batia.reference
      this.show = true
    },
    hide () {
      this.group = null
      this.show = false
    },
    cambio (metadata) {
      let team = { id: metadata.id, ...metadata.data() }
      if (team.name === 'A') this.alba = team
      if (team.name === 'B') this.batia = team
      // this.$emit('cambio', this.group)
    }
  },
  watch: {
    albaReference (val) {
      if (val) val.onSnapshot(this.cambio)
    },
    batiaReference (val) {
      if (val) val.onSnapshot(this.cambio)
    }
  }
  // filters: {
  //   gname (val) {
  //     return groupName(val)
  //   }
  // }
}
</script>
<style >
.bordered {
  border: 1px solid black;
}
</style>
