<template lang="pug">
tbody
  tr(v-if="group", v-for="(round, index) in [1, 2, 3, 4, 5, 6, 7, 8]")
    editableResult(
      :roundInfo="group.alba.results[index]",
      :editable="editable",
      :perfect="perfect",
      :team="group.alba",
      :mode="resToGet",
      @cambio="$emit('cambio')",
      :index="index"
    )
    editableResult(
      :roundInfo="group.batia.results[index]",
      :editable="editable",
      @cambio="$emit('cambio')",
      :perfect="perfect",
      :team="group.batia",
      :mode="resToGet",
      :index="index"
    )

  tr(v-if="allShowed")
    td.result.text-center
      .display-2.text-center {{ totals.alba }}
    td.result.text-center
      .display-2.text-center {{ totals.batia }}
  tr.result.text-center(v-if="allShowed")
    td(colspan="2")
      .display-2 {{ totals.sum }}
</template>
<script>
// import EditableElement from '@/components/EditableElement'
// import NegRoundDialog from '@/components/NegRoundDialog'
import editableResult from '@/components/results/editableResult'
// import editTeamDialog from '@/components/editTeamDialog.vue'
export default {
  name: 'Round',
  props: {
    group: { required: true },
    mode: { default: 'negotiation' },
    perfect: { default: false },
    editable: { default: false }
  },
  components: {
    // NegRoundDialog,
    // EditableElement
    // editTeamDialog,
    editableResult
  },
  // created () {
  //   if (this.savedCovers) this.covers = this.savedCovers
  // },
  computed: {
    totals () {
      let alba = this.group.alba.results[7].acumulado
      let batia = this.group.batia.results[7].acumulado
      return {
        alba,
        batia,
        sum: alba + batia
      }
    },
    allShowed () {
      if (this.perfect) return true
      let albaCovers = this.group.alba.results.every((res) => !res.cover)
      let batiaCovers = this.group.batia.results.every((res) => !res.cover)
      return albaCovers && batiaCovers
    },
    resToGet () {
      if (this.mode === 'Utilidad' || this.mode === 'Utility') {
        return 'utilidades'
      }
      if (this.mode === 'Negociacion' || this.mode === 'Negotation') {
        return 'negociacion'
      }
      if (this.mode === 'Acumulado' || this.mode === 'Acumulated') {
        return 'acumulado'
      }
      return 'edition'
    }
    // computedClases () {
    //   let covers = this.covers
    //   return this.getClassList(covers)
    // },
    // uncovers () {
    //   let covers = this.covers
    //   let rounds = []
    //   for (var i = 0; i < covers.length; i++) {
    //     let round = !!(covers[i][0] || covers[i][1])
    //     rounds.push(round)
    //   }
    //   return this.perfect
    //   // return rounds.reduce((prev,act)=>{
    //   //   return prev || act ? true : false
    //   // })
    // }
  },
  data () {
    return {
      // computedClases:null,
      // albaRef: this.alba.reference,
      // batiaRef: this.batia.reference,
      covers: [
        [true, true],
        [true, true],
        [true, true],
        [true, true],
        [true, true],
        [true, true],
        [true, true],
        [true, true]
      ]
    }
  },
  methods: {
    respose (group, index, team) {
      return group[this.resToGet][index][team]
    },
    // clases (index, team) {
    //   if (this.mode === 'Edicion' || this.mode === 'Edition') return this.computedClases[index][team].replace('cover', '')
    //   return this.computedClases[index][team]
    // },
    // updateTeam (event) {
    //   let updatedGroup = {}
    //   updatedGroup.name = this.group.name
    //   updatedGroup.id = this.group.id
    //   updatedGroup.teams = event.teams

    //   let storage = new storageService('game')

    //   storage.updateGroup(this.gameID, updatedGroup).then(
    //     done => {
    //       this.$emit('actualiza')
    //       console.log(done)
    //     },
    //     fail => console.log(fail)
    //   )

    //   this.$refs.Supermodal.hide()
    // },
    // toggleCover (team, index) {
    //   let newVal = !this.covers[index][team]
    //   this.covers[index].splice(team, 1, newVal)
    // },
    // coverAll () {
    //   this.covers = [
    //     [true, true],
    //     [true, true],
    //     [true, true],
    //     [true, true],
    //     [true, true],
    //     [true, true],
    //     [true, true],
    //     [true, true]
    //   ]
    // },
    // uncoverAll () {
    //   this.covers = [
    //     [false, false],
    //     [false, false],
    //     [false, false],
    //     [false, false],
    //     [false, false],
    //     [false, false],
    //     [false, false],
    //     [false, false]
    //   ]
    // },
    // getClassList (val) {
    //   let classes = []
    //   for (var i = 0; i < val.length; i++) {
    //     let round = [
    //       this.getClasses(0, i),
    //       this.getClasses(1, i)
    //     ]
    //     classes.push(round)
    //   }
    //   return classes
    // },
    openUpdate (team, round, value = null) {
      let r = parseInt(round) + 1
      if (value) {
        this.$refs.editTeamDialog.selected = value
      }
      this.$refs.editTeamDialog.open(team, r)
    }
  },
  mounted () {
    // this.albaListener = this.albaRef.onSnapshot(function (doc) {
    //   console.log('Current data: ', doc.data())
    // })
  },
  watch: {
    // albaRef (val) {
    //   console.log('reference setter')
    //   val.onSnapshot(function (doc) {
    //     console.log('Current data: ', doc.data())
    //   })
    // },
    // group (val) {
    //   this.alba = val.alba
    //   this.albaRef = this.$firestore.models.teams.db.doc(val.alba.id)
    //   this.batia = val.batia
    //   this.batiaRef = this.$firestore.models.teams.db.doc(val.batia.id)
    // },
    // uncovers (val) {
    //   this.$emit('input', !val)
    // }
  }
}
</script>
<style>
.cover {
  color: slategray;
  background-color: slategray;
}
,
.redcolor {
  color: #fd1a27;
}
.grencolor {
  color: #03c526;
}
.bluecolor {
  color: #1b58d4;
}
.specialRound > h3 {
  font-weight: 900;
}
.result {
  font-weight: 900;
  background-color: orange !important;
  color: white;
}
</style>
