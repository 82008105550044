import { Pie } from 'vue-chartjs'

export default {
  name: 'pie-chart',
  extends: Pie,
  mounted () {
    this.render()
  },
  computed: {
    dat () {
      return {
        labels: this.data.labels,
        datasets: this.data.datasets
      }
    }
  },
  watch: {
    'dat': function () {
      this.render()
    }
  },
  methods: {
    render () {
      this.renderChart(this.dat, this.options)
    }
  },
  props: ['data'],
  data () {
    return {
      options: {
        responsive: true,
        legend: {
          labels: {
            fontSize: 16
          }
        },

        tooltips: {
          bodyFontSize: 17
        }
      }
    }
  }
}
