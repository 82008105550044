<template lang="pug">
.container
  .row
    .col
      groupDetail(@cambio="refreshGame", ref="groupDetail")
  .row.justify-content-center(v-if="loading")
    LoadingComponent
  .row.justify-content-center.justify-center(v-else)
    .col.col-md2.text-center.angosta(v-for="(group, index) in groups")
      groupTable(
        @cambio="setGroup",
        @detail="groupDetail($event)",
        :allshowed="allshowed",
        :group="group",
        :mode="mode",
        :key="group.id"
      )
    .col.text-center.angosta(v-show="allshowed")
      perfectGame(:allshowed="allshowed")

  .row.justify-content-center
    .col.text-center
      v-btn-toggle(v-model="mode", mandatory)
        v-btn(v-for="b in modeoptions", :key="b" :value="b")
          h3 {{ b }}
        v-btn(value="Edition")
          h3 {{ $words.get('edit') }}
        v-btn(
          :value="mode",
          color="info ligthen-2",
          v-if="!allshowed",
          dark,
          @click="allshowed = true"
        )
          h3 {{ $words.get('reveal_all') }}
        v-btn(
          :value="mode",
          color="info ligthen-2",
          v-else,
          dark,
          @click="allshowed = false"
        )
          h3 {{ $words.get('hide_all') }}

  .row.justify-content-center(v-if="waiting")
    LoadingComponent(style="position: absolute")
  .row.justify-center.mt-4(v-if="allshowed")
    BarChart(:labels="barChartLabels", :height="300", :data="barChartData")

  .row.mt-4
    DeleteConfirmation(
      ref="Confirmation",
      model="games",
      @success="$router.push({ name: 'main' })"
    )
    v-btn(
      block,
      color="red",
      text,
      @click="$refs.Confirmation.open(game_id, 'dinamica')"
    ) Eliminar Dinamica
  v-tooltip(top)
    template(v-slot:activator="{ on, attrs }")
      v-btn.mb-10(
        v-on="on",
        @click="$router.push({ name: 'main' })",
        color="secondary",
        fixed,
        absolute,
        fab,
        large,
        dark,
        bottom,
        right
      )
        v-icon(dark) fas fa-reply
    span Regresar
</template>
<script>
//
import { perfect, perfectRounds, groupName, REF } from '@/assets/dinamicaUtils'
// import Results from '@/assets/results'
// import menudiv from '@/components/results/menuDiv'
// // import Loader from '@/assets/Loader.vue'
//
// import ResultsByGroup from '@/components/charts/ResultsByGroup'
// import RoundsTableView from '@/components/results/RoundsTableView.vue'
import groupTable from '@/components/results/groupTable.vue'
import perfectGame from '@/components/results/perfectGame.vue'
import BarChart from '@/components/charts/BarChart'
import PieChart from '@/components/charts/PieChart'
import groupDetail from '@/components/team/TeamDetail'
// import ModalDatos from '@/components/results/ModalDatos'
// import storageService from '@/services/storageService'
const get_acumulado = (groups) => {
return groups.map((g) => {
        let ronda = g.ronda === 9 ? 7 : g.ronda - 1
        let [alba, batia]= g.acumulado[ronda]
        let capita_potencial = ronda === 0 ? 0
            : perfect[ronda].acum_capita
        let capita = capita_potencial - (alba + batia)
        return {
          alba,
          batia,
          capita,
          ronda
        }
      })

}


const ALF = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z'
]
export default {
  name: 'Results',
  components: {
    groupTable,
    perfectGame,
    // BackButton,
    // ResultsByGroup,
    // RoundsTableView,
    BarChart,
    PieChart,
    // ModalDatos,
    groupDetail
    // Loader
  },
  filters: {
    upperName: function (value) {
      return groupName(value)
    },
    pretty (val) {
      // let parsed = JSON.parse(val)
      return JSON.stringify(val, null, 2)
    },
    gname (val) {
      return groupName(val)
    }
  },
  data () {
    return {
      alphabet: ALF,
      groups: [],
      groupsProxy: [],
      loading: false,
      waiting: false,
      mode: this.$words.get('negociacion'),
      detail: { group: null, show: false },
      // gameService:new storageService('game'),
      teamService: this.$firestore.models.teams.db,
      // gameService: this.$django.models.game,
      game_id: this.$route.params.id,
      game: null,
      proxy: null,
      perfect: perfect,
      perfectRounds: perfectRounds,
      group_list: [],
      charH: 300,
      allshowed: false,
      x: false,
      checkCovered: false
    }
  },
  created () {
    this.getGame()
  },
  computed: {
    modeoptions () {
      if (this.$words.lang === 'ES') {
        return ['Utilidad', 'Acumulado', 'Negociacion']
      } else {
        return ['Utility', 'Acumulated', 'Negotation']
      }
    },
    barChartLabels () {
      return this.groupsProxy.map(
        (g) => `Universo ${g.name}-${g.name}${g.name}`
      )
    },
    resumenAcumulado () {
      return get_acumulado(this.groupsProxy)
    },
    barChartData () {
      let datasets = [
        {
          label: 'Alba',
          backgroundColor: REF.alba.color_light,
          data: []
        },

        {
          label: 'Batia',
          backgroundColor: REF.batia.color_light,
          data: []
        },

        {
          label: 'Capita',
          backgroundColor: REF.capita.color_light,
          data: []
        }
      ]
      this.resumenAcumulado.forEach((el) => {
        datasets[0].data.push(el.alba)
        datasets[1].data.push(el.batia)
        datasets[2].data.push(el.capita)
      })

      return datasets
    },
    acumuladoGeneral () {
      return this.resumenAcumulado.reduce(
        (ant, curr) => {
          return {
            alba: ant.alba + curr.alba,
            batia: ant.batia + curr.batia,
            capita: ant.capita + curr.capita
          }
        },
        { alba: 0, batia: 0, capita: 0 }
      )
    },
    pieChart () {
      return {
        labels: ['Alba', 'Batia', 'Capita'],
        datasets: [
          {
            backgroundColor: [
              REF.alba.color_light,
              REF.batia.color_light,
              REF.capita.color_light
            ],
            data: this.resumenAcumulado.reduce(
              (ant, curr) => {
                return [
                  ant[0] + curr.alba,
                  ant[1] + curr.batia,
                  ant[2] + curr.capita
                ]
              },
              [0, 0, 0]
            )
          }
        ]
      }
    }
  },
  methods: {
    async getGame () {
      this.$alert('reconecting', 'Actualizando')
      this.loading = true
      const id = this.$route.params.id
      let groups = await this.$firestore.models.games.groups(id)
      groups.sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
      this.groups = [
        ...groups.map(this.$firestore.models.teams.__resultsOfGroup)
      ]
      this.groupsProxy = [...this.groups]
      this.loading = false
      this.$alert('hide')
    },
    setGroup (group) {
      let idx = this.groupsProxy.map((g) => g.name).indexOf(group.name)
      this.groupsProxy.splice(idx, 1, group)
    },
    refreshGame (metadata) {
      // console.log(metadata)
      let groupIdx = this.groups.map((g) => g.name).indexOf(metadata.group)
      this.groups[groupIdx] = metadata
    },
    groupDetail (group) {
      this.$refs.groupDetail.showUniverse(group)
    }
  }
}
</script>
<style>
.col {
  padding: 1px;
}
.alba {
  max-width: 10px;
  background-color: rgba(255, 112, 2, 0.25);
  color: #000000;
}
.batia {
  max-width: 10px;
  background-color: rgba(83, 150, 0, 0.25);
  color: #000000;
}

.batiatext {
  color: rgba(83, 150, 0, 1);
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

.albatext {
  color: rgba(255, 112, 2, 1);

  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

.albatext.cover,
.batiatext.cover,
.redcolor.cover,
.grencolor.cover,
.bluecolor.cover {
  color: slategray;
  background-color: slategray;
}
.btn-sm.active {
  background-color: #ff6300 !important;
  outline-color: #ff6300 !important;
  font-style: bold;
}
.btn-sm.focus:hover {
  background-color: #eca170 !important;
}
</style>
