const SCENARIOS = {
  '30-30': [11, 11],
  '30-20': [2, 18],
  '30-10': [2, 15],
  '20-30': [18, 2],
  '20-20': [8, 8],
  '20-10': [3, 15],
  '10-30': [15, 2],
  '10-20': [15, 3],
  '10-10': [5, 5]
}
const REF = {
  batia: {
    name: 'Batia',
    color_dark: 'rgba(255,112,2,1)',
    color_medium: 'rgba(255,112,2,0.5)',
    color_light: 'rgba(255,112,2,0.25)'
  },
  alba: {
    name: 'Alba',
    color_dark: 'rgba(83,150,0,1)',
    color_medium: 'rgba(83,150,0,0.5)',
    color_light: 'rgba(83,150,0,0.25)'
  },
  capita: {
    name: 'Capita',
    color_dark: 'rgba(43,159,255,1)',
    color_medium: 'rgba(43,159,255,0.5)',
    color_light: 'rgba(43,159,255,0.25)'
  }
}

const perfect = [
  {
    // Ronda 1
    A: 30,
    B: 30,
    Utilidad_A: 11,
    Utilidad_B: 11,
    acum_A: 11,
    acum_B: 11,
    acum_capita:22
  },
  {
    // Ronda 2
    A: 30,
    B: 30,
    Utilidad_A: 11,
    Utilidad_B: 11,
    acum_A: 22,
    acum_B: 22,
    acum_capita:44
  },
  {
    // Ronda 3
    A: 30,
    B: 30,
    Utilidad_A: 11,
    Utilidad_B: 11,
    acum_A: 33,
    acum_B: 33,
    acum_capita:66
  },
  {
    // Ronda 4
    A: 30,
    B: 30,
    Utilidad_A: 22,
    Utilidad_B: 22,
    acum_A: 55,
    acum_B: 55,
    acum_capita:110
  },
  {
    // Ronda 5
    A: 30,
    B: 30,
    Utilidad_A: 11,
    Utilidad_B: 11,
    acum_A: 66,
    acum_B: 66,
    acum_capita:132
  },
  {
    // Ronda 6
    A: 30,
    B: 30,
    Utilidad_A: 11,
    Utilidad_B: 11,
    acum_A: 77,
    acum_B: 77,
    acum_capita:154
    
  },
  {
    // Ronda 7
    A: 30,
    B: 20,
    Utilidad_A: 2,
    Utilidad_B: 72,
    acum_A: 79,
    acum_B: 149,
    acum_capita:228
  },
  {
    // Ronda 8
    A: 20,
    B: 30,
    Utilidad_A: 72,
    Utilidad_B: 2,
    acum_A: 151,
    acum_B: 151,
    acum_capita:302
  }
]

const perfectMatrix = {
  name: 0,
  ronda: 8,
  edition: [
    [30, 30],
    [30, 30],
    [30, 30],
    [30, 30],
    [30, 30],
    [30, 30],
    [30, 20],
    [20, 30]
  ],
  negociacion: [
    [30, 30],
    [30, 30],
    [30, 30],
    [30, 30],
    [30, 30],
    [30, 30],
    [30, 20],
    [20, 30]
  ],
  utilidades: [
    [11, 11],
    [11, 11],
    [11, 11],
    [22, 22],
    [11, 11],
    [11, 11],
    [72, 2],
    [2, 72]
  ],
  acumulado: [
    [11, 11],
    [22, 22],
    [33, 33],
    [55, 55],
    [66, 66],
    [77, 77],
    [149, 85],
    [151, 151]
  ]
}

let perfectAlba = []
let perfectBatia = []
for (let round = 0; round < perfectMatrix.acumulado.length; round++) {
  perfectAlba.push(
    {
      cover: false,
      acumulado: perfectMatrix.acumulado[round][0],
      edition: perfectMatrix.edition[round][0],
      negociacion: perfectMatrix.negociacion[round][0],
      utilidades: perfectMatrix.utilidades[round][0],
      value: perfectMatrix.negociacion[round][0]
    })
  perfectBatia.push(
    {
      cover: false,
      acumulado: perfectMatrix.acumulado[round][1],
      edition: perfectMatrix.edition[round][1],
      negociacion: perfectMatrix.negociacion[round][1],
      utilidades: perfectMatrix.utilidades[round][1],
      value: perfectMatrix.negociacion[round][1]
    }
  )
}

const perfectRounds = {
  ...perfectMatrix,
  alba: {
    id: 0,
    reference: null,
    group: 'X',
    name: 'A',
    results: perfectAlba
  },
  batia: {
    reference: null,
    group: 'X',
    name: 'B',
    id: 0,
    results: perfectBatia

  }

}

function groupName (nameidx) {
  const ALF = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  return ALF[nameidx].toUpperCase()
}

export {
  SCENARIOS,
  REF,
  perfect,
  perfectRounds,
  groupName

}
