<template lang="pug">
.angosta
  v-simple-table
    template(v-slot:default, body, v-if="processedGroup")
      thead.pointer
        tr.text-white(@click="$emit('detail', processedGroup)")
          th.text-center.alba--text
            .display-3 {{ processedGroup.name }}
          th.text-center.batia--text
            .display-3 {{ processedGroup.name }}{{ processedGroup.name }}
      RoundsTableView(
        ref="RoundsTableView",
        :perfect="allshowed",
        :editable="true",
        :group="processedGroup",
        :alba="processedGroup.alba",
        :batia="processedGroup.batia",
        :mode="mode"
      )
</template>
<script>
import RoundsTableView from '@/components/results/RoundsTableView.vue'
export default {
  components: {
    RoundsTableView
  },
  name: 'Group',
  props: {
    group: {
      required: true
    },
    mode: {},
    allshowed: {}
  },
  data () {
    return {
      processedGroup: null
    }
  },
  methods: {
    setGroup (group) {
      this.processedGroup = this.$firestore.models.teams.__resultsOfGroup(
        group
      )
    },
    refreshTeam (team, data) {
      let proxyGroup = { ...this.processedGroup }
      proxyGroup[team] = data
      this.setGroup(proxyGroup)
      this.$alert('hide')
    }
  },
  // watch: {
  //   group: {
  //     handler(){
  //       setGroup'

  //     }
  //   }
  // },
  mounted () {
    const self = this
    this.setGroup(this.group)
    this.processedGroup.alba.reference.onSnapshot((doc) => {
      self.$alert('reconecting', 'Actualizando')
      self.refreshTeam('alba', { id: doc.id, ...doc.data() })
      self.$emit('cambio', self.processedGroup)
    })
    this.processedGroup.batia.reference.onSnapshot((doc) => {
      self.$alert('reconecting', 'Actualizando')
      self.refreshTeam('batia', { id: doc.id, ...doc.data() })
      self.$emit('cambio', self.processedGroup)
    })
  }
}
</script>
